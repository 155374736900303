import { DB } from "dcp-types"
import { getDomain } from "./env"

export const DebounceDelay = {
  FastInputChange: 500,
  InputChange: 1000,
  InputIdle: 5000,
  LoadingState: 300,
  HoverElementEndDelay: 500,
  AcknowledgeMessage: 2000,
  TransitionDuration: 300,
  CollapseTransitionDuration: 600,
  SelectAfterFocus: 50,
} as const

export const TimeoutDelay = {
  EditorInitialization: 350,
  Idle: 1500,
  LazyLoad: 250,
}
export const Intervals = {
  PoaRefetch: 10000,
  SystemInformationRefetch: 60000,
}

export const getSetPasswordUrl = (
  email: string,
  token: string,
  type: "invite" | "reset",
) => {
  const domain = getDomain()

  return `${domain}/de/auth/activate-session?redirect=${encodeURIComponent(
    "/de/auth/set-password",
  )}&email=${email}&${
    type === "invite" ? "invite_token" : "reset_token"
  }=${token}`
}
