/**
 * feature flags
 */
export const FEATURE_FLAG_ENABLE_GROUPS = false
export const FEATURE_FLAG_ENABLE_REGISTRATION = false
export const FEATURE_FLAG_ENABLE_UNFINISHED_FEATURES = false

export const isBrowser = () => typeof window !== "undefined"
export const isServer = () => typeof window === "undefined"
export const isDevelopment = () => process.env.NODE_ENV === "development"
export const isProduction = () => process.env.NODE_ENV === "production"
export const isTest = () => process.env.NODE_ENV === "test"

export const getDomain = (): string =>
  typeof window !== "undefined"
    ? window.location.origin
    : (process.env.HOST_DOMAIN as string)

export const NEXT_PUBLIC_HELP_CENTER_URL = "https://support.rightflow.de"

/** custom app env */
export const NEXT_PUBLIC_APP_ENV = process.env.NEXT_PUBLIC_APP_ENV

/** Azure */
export const AZURE_DOCUMENT_INTELLIGENCE_API_KEY =
  process.env.AZURE_DOCUMENT_INTELLIGENCE_API_KEY
export const AZURE_DOCUMENT_INTELLIGENCE_ENDPOINT =
  process.env.AZURE_DOCUMENT_INTELLIGENCE_ENDPOINT

export const AZURE_OPENAI_ENDPOINT = process.env.AZURE_OPENAI_ENDPOINT
export const AZURE_OPENAI_API_KEY = process.env.AZURE_OPENAI_API_KEY

export const AZURE_OPENAI_GPT35_ENDPOINT =
  process.env.AZURE_OPENAI_GPT35_ENDPOINT
export const AZURE_OPENAI_GPT35_API_KEY = process.env.AZURE_OPENAI_GPT35_API_KEY

export const AZURE_MAPS_API_KEY = process.env.AZURE_MAPS_API_KEY

export const AZURE_STORAGE_CONNECTION_STRING =
  process.env.AZURE_STORAGE_CONNECTION_STRING

/** Application Settings and Default */

const numberOrDefault = (value: string | undefined, defaultValue: number) => {
  if (value !== undefined) {
    const parsed = Number.parseInt(value)
    if (!Number.isNaN(parsed)) {
      return parsed
    }
  }
  return defaultValue
}

/**
 * Global settings for the application
 *
 * These settings are used as defaults for the application:
 * - *defaultWorkfowScheduleDelay*: The default delay for scheduling a workflow run
 * - *defaultPaginationLimit*: The default limit for paginated results
 */

const platformEmailDomain = process.env.PLATFORM_EMAIL_DOMAIN || "rightflow.de"

export const GlobalSettings = {
  defaultWorkfowScheduleDelay: numberOrDefault(
    process.env.DEFAULT_WORKFLOW_SCHEDULE_DELAY,
    1000 * 60 * 5,
  ),
  defaultPaginationLimit: numberOrDefault(
    process.env.DEFAULT_PAGINATION_LIMIT,
    10,
  ),
  logging: {
    axiom: {
      dataset: process.env.AXIOM_DATASET,
      token: process.env.AXIOM_TOKEN,
    },
  },
  email: {
    platformEmailDomain,
    systemEmailAddress: `Rightflow <info@${platformEmailDomain}>`,
    defaultSubject: "Nachricht",
    sendMessages: process.env["SEND_MESSAGES"] === "true",
    customEmailSyncMinutes: 5,
    minimumEmailSyncDelaySeconds: 10,
  },
  delays: {
    defaultSignatureRequestAccessTokenExpiryDelay: 1000 * 60 * 60 * 24 * 2, // 2 days,
  },
  healthchecksIoPingKey: process.env.HEALTHCHECKS_IO_PING_KEY,
  usage: {
    natifai: {
      limit: process.env.NATIF_AI_DAILY_USAGE_LIMIT
        ? Number.parseInt(process.env.NATIF_AI_DAILY_USAGE_LIMIT)
        : 100,
    },
  },
  documentProcessing: {
    currentVersion: 2,
  },
} as const
