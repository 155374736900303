import { getTranslation } from "@/i18n"
import type { document_category } from "dcp-types"
import de from "../app/[locale]/(document)/_messages/de.json"

export type DataUrl = string

export function downloadBlob(blob: Blob | DataUrl, fileName: string) {
  const url = isDataUrl(blob) ? blob : window.URL.createObjectURL(blob)
  const a = window.document.createElement("a")

  window?.document?.body?.appendChild(a)
  a.style.cssText = "display: none"
  a.href = url
  a.download = fileName
  a.click()
  window.URL.revokeObjectURL(url)
  a.remove()
}

export default function isBlob(value: Blob | unknown): value is Blob {
  if (typeof Blob === "undefined") {
    return false
  }

  return value instanceof Blob || toString.call(value) === "[object Blob]"
}

export function dataURLtoBlob(dataurl: string): Blob {
  const arr = dataurl.split(",")
  const mime = arr
    .at(0)
    ?.match(/:(.*?);/)
    ?.at(1)
  const bstr = atob(arr[1])
  const n = bstr.length
  const u8arr = new Uint8Array(n)

  for (let i = 0; i < n; i++) {
    u8arr[i] = bstr.charCodeAt(i)
  }

  return new Blob([u8arr], { type: mime })
}

export async function blobToDataUrl(
  blob?: Blob | File | null,
): Promise<string> {
  const emptyData = "data:"

  if (!blob || !isBlob(blob)) {
    return emptyData
  }

  if (typeof FileReader !== "undefined") {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => resolve(reader.result as string)
      reader.onerror = () => reject(reader.error)
      reader.onabort = () => reject(new Error("Read aborted"))
      reader.readAsDataURL(blob)
    })
  }

  if (typeof Buffer !== "undefined") {
    const buffer = Buffer.from(await blob.arrayBuffer())
    const dataUrl = `data:${blob.type};base64,${buffer.toString("base64")}`

    return dataUrl
  }

  return emptyData
}

export function isDataUrl(dataUrl: unknown): dataUrl is DataUrl {
  return typeof dataUrl === "string" && dataUrl.startsWith("data:")
}

export function fileNameByDocumentCategory(
  category: document_category,
): string {
  const { t } = getTranslation(de)
  const today = new Date()

  return `${t(
    `category.${category ?? "other"}.label`,
  )}-${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}.pdf`
}

export function hash(text: string) {
  let hash = 5381
  let index = text.length

  while (index) {
    hash = (hash * 33) ^ text.charCodeAt(--index)
  }

  return hash >>> 0
}

export function shortHash(text: string) {
  let hash = 5381
  let index = text.length

  while (index) {
    hash = (hash * 33) ^ text.charCodeAt(--index)
  }

  return (hash >>> 0).toString(36)
}
