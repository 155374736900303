import { serverTranslation, useTranslation } from "@/i18n"
import type { document_category } from "dcp-types"
import de from "../_messages/de.json"
import { useMemo } from "react"

export default function useDocumentCategoryLabel() {
  const { t } = useTranslation(de)

  const getDocumentCategoryLabel = useMemo(
    () => (category?: document_category | null) =>
      t(category ? `category.${category}.label` : "category.unknown.label"),
    [t],
  )

  return {
    getDocumentCategoryLabel,
  }
}

export const getDocumentCategoryLabel = async (
  category?: document_category | null,
) => {
  const { t } = await serverTranslation(de)

  return t(category ? `category.${category}.label` : "category.unknown.label")
}
