import { type ThemeOptions, createTheme, lighten } from "@mui/material/styles"
import {
  dark,
  light,
  primary,
  secondary,
  error,
  warning,
  success,
  info,
} from "./tokens/colors"
import type { CSSProperties } from "react"
import tokenBreakpoints from "./tokens/breakpoints"
import { Record } from "twilio/lib/twiml/VoiceResponse"
import { APP_LAYOUT_MAIN_CONTAINER_ID } from "@/app/_components/AppPortalLayout"

/**
 * here we initialize the mui theme based on our framework agnostic design tokens
 * the same tokens are used in tailwind.config.js
 */

declare module "@mui/material/styles" {
  interface TypographyVariants {
    tableTitle: CSSProperties
    captionTiny: CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    tableTitle?: CSSProperties
    captionTiny?: CSSProperties
  }

  interface BreakpointOverrides {
    xs: true
    sm: true
    md: true
    lg: true
    xl: true
    "2xl": true
    "3xl": true
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    tableTitle: true
    captionTiny: true
  }
}

const breakpoints: ThemeOptions["breakpoints"] = {
  values: tokenBreakpoints,
}

const typography: ThemeOptions["typography"] = {
  tableTitle: {
    fontSize: "16px",
    letterSpacing: "0.17 px",
    fontWeight: 500,
    lineHeight: "24px",
  },
  captionTiny: {
    fontSize: "10px",
  },
}

const components: ThemeOptions["components"] = {
  MuiDialog: {
    /**
     * - these settings allow us to render the dialog in the main content area, without covering the aside view
     * - disableEnforceFocus is important to allow editing the aside content while the dialog is open
     * - the APP_LAYOUT_MAIN_CONTAINER_ID is only available in the AppPortalLayout component, everywhere else (e.g. POA page) the dialog will be rendered in the body
     */
    defaultProps: {
      disableEnforceFocus: true,
      container: () => document.getElementById(APP_LAYOUT_MAIN_CONTAINER_ID),
      slotProps: {
        root: {
          style: {
            // important to ensure the dialog gets positioned relative to the main content
            position: "absolute",
          },
        },
        backdrop: {
          style: {
            // important to ensure the backdrop gets positioned relative to the main content
            position: "absolute",
          },
        },
      },
    },
  },
  MuiContainer: {
    defaultProps: {
      maxWidth: "xl",
    },
    styleOverrides: {},
  },
  MuiButton: {
    defaultProps: {},
    styleOverrides: {
      root: {
        textTransform: "none",
        fontWeight: 500,
      },
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        textTransform: "none",
        fontWeight: 500,
      },
    },
  },
  MuiAutocomplete: {
    defaultProps: {
      limitTags: 2,
    },
    styleOverrides: {},
  },
  MuiTab: {
    defaultProps: {},
    styleOverrides: {
      root: {
        textTransform: "none",
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      autoComplete: "off",
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        minHeight: "48px", // align height with AppBar
      },
    },
  },
}

export const muiLightTheme = createTheme({
  typography,
  breakpoints,
  components,
  palette: {
    mode: "light",
    primary: { main: primary },
    secondary: { main: secondary },
    error: { main: error, light: lighten(error, 0.25) },
    warning: { main: warning, light: lighten(error, 0.25) },
    success: { main: success, light: lighten(success, 0.25) },
    info: { main: info },
    background: { default: light.main, paper: light.main },
  },
})

export const muiDarkTheme = createTheme({
  typography,
  breakpoints,
  components,
  palette: {
    mode: "dark",
    primary: { main: primary },
    secondary: { main: secondary },
    error: { main: error, light: lighten(error, 0.5) },
    warning: { main: warning, light: lighten(error, 0.5) },
    success: { main: success, light: lighten(success, 0.5) },
    info: { main: info },
    background: { default: dark.main, paper: dark.off },
  },
})
