export const primary = "#2979FF"

export const secondary = "#14a37f"

export const error = "#d32f2f"

export const warning = "#ed6c02"

export const success = "#2e7d32"

export const info = "#0288d1"

export const dark = { main: "#0B0B0B", off: "#151515" }
export const light = { main: "#FFFFFF", off: "#EAEAEA" }

const colors = {
  primary,
  secondary,
  dark,
  light,
  error,
  warning,
  success,
  info,
  gradient: {
    primary: {
      from: primary,
      to: secondary,
      toPosition: "bottom left",
      backgroundImage: `linear-gradient(to bottom left, ${primary}, ${secondary})`,
    },
  },
}

export default colors
