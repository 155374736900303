import type { UserRouter } from "@/server/routers/entity/routers/user"
import type { UserData } from "@/utils/lib"
import type { inferRouterInputs } from "@trpc/server"
import type { organization_type } from "dcp-types"

export function isUserOfServiceProvider(user: UserData | null) {
  if (!user) return false

  const organizationId = user.organizationId
  const oty = user.type

  return Boolean(organizationId) && oty === "service_provider"
}

export function isUserDataOfServiceProvider(
  userData: UserData | null | undefined,
) {
  if (!userData) return false

  const organizationId = userData?.organizationId
  const oty: organization_type | undefined = userData?.type

  return Boolean(organizationId) && oty === "service_provider"
}

export function isUserOfSystemAdmin(user: UserData | null) {
  if (!user) return false

  return user?.role === "system_admin"
}

export function isUserDataOfSystemAdmin(userData: UserData | null | undefined) {
  if (!userData) return false

  return userData?.role === "system_admin"
}

export function isUserOfLawfirm(user: UserData | null) {
  if (!user) return false

  const organizationId = user?.organizationId
  const oty = user.type

  return Boolean(organizationId) && oty === "law_firm"
}

export function isUserDataOfLawfirm(userData: UserData | null | undefined) {
  if (!userData) return false

  const organizationId = userData?.organizationId
  const oty: organization_type | undefined = userData?.type

  return Boolean(organizationId) && oty === "law_firm"
}

export function isAdmin(userData?: UserData | null) {
  if (!userData) return false

  return (
    userData?.roles?.includes("law_firm_admin") ||
    userData?.roles?.includes("service_provider_admin")
  )
}

export function isClerk(userData?: UserData | null) {
  if (!userData) return false

  return (
    userData?.roles?.includes("law_firm_clerk") ||
    userData?.roles?.includes("service_provider_clerk")
  )
}

export function isWithoutRole(userData?: UserData | null) {
  if (!userData) return false

  return userData?.roles?.length === 0
}

export function getInitialTableFilterOptions() {
  return {
    search: undefined,
    groups: undefined,
    roles: undefined,
    showInvited: true,
  } satisfies inferRouterInputs<UserRouter>["list"]["filterOptions"]
}
