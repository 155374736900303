import {
  mdiEmailOutline,
  mdiFileDocumentOutline,
  mdiFileExcelOutline,
  mdiFilePdfBox,
  mdiFilePowerpointOutline,
  mdiFileWordOutline,
  mdiImageOutline,
  mdiPaperclip,
  mdiZipBoxOutline,
} from "@mdi/js"
import Icon from "@mdi/react"

export interface FileIconProps {
  mimeType?: string
  fileNameExtension?: string
  size?: number
}

export function FileIcon({
  mimeType,
  fileNameExtension,
  size = 1,
}: FileIconProps) {
  /**
   * this function can be extended by supporting more common mime types: https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
   */
  if (mimeType?.includes("pdf"))
    return <Icon className="text-red-500" path={mdiFilePdfBox} size={size} />
  if (mimeType?.includes("image"))
    return <Icon className="text-blue-400" path={mdiImageOutline} size={size} />
  if (
    (mimeType?.includes("application/octet-stream") ||
      mimeType?.includes("message/rfc822")) &&
    fileNameExtension === "eml"
  )
    return <Icon className="text-blue-500" path={mdiEmailOutline} size={size} />
  if (mimeType?.includes("text"))
    return (
      <Icon
        className="text-gray-500"
        path={mdiFileDocumentOutline}
        size={size}
      />
    )
  if (
    mimeType?.includes("application/vnd.ms-excel") ||
    mimeType?.includes("spreadsheetml")
  )
    return (
      <Icon className="text-green-400" path={mdiFileExcelOutline} size={size} />
    )
  if (
    mimeType?.includes("application/vnd.ms-powerpoint") ||
    mimeType?.includes("presentationml")
  )
    return (
      <Icon
        className="text-orange-500"
        path={mdiFilePowerpointOutline}
        size={size}
      />
    )
  if (
    mimeType?.includes("application/msword") ||
    mimeType?.includes("wordprocessingml")
  )
    return (
      <Icon className="text-indigo-400" path={mdiFileWordOutline} size={size} />
    )
  if (
    mimeType?.includes("application/zip") ||
    mimeType?.includes("application/x-7z-compressed") ||
    mimeType?.includes("application/x-freearc") ||
    mimeType?.includes("application/gzip")
  )
    return (
      <Icon className="text-gray-500" path={mdiZipBoxOutline} size={size} />
    )

  // fallback
  return <Icon className="text-gray-500" path={mdiPaperclip} size={size} />
}
