"use client"

import { Stack, type StackProps } from "@/components/ui/layout/Stack"
import { type Breakpoint, useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles"

import type { HTMLAttributes, ReactNode } from "react"

export const FORM_ROW_MAX_WIDTH = 600

export interface FormRowProps extends HTMLAttributes<HTMLDivElement> {
  maxWidth?: number
  /**
   * @deprecated use `children` instead
   */
  mainRowElement?: ReactNode
  sideRowElement?: ReactNode
  breakpoint?: Breakpoint
  mainRowProps?: StackProps
  sideRowProps?: StackProps
  children?: ReactNode
}
export const getFormRowMaxSize = (maxWidth: number) => maxWidth / 8

export default function FormRow({
  mainRowElement,
  sideRowElement,
  maxWidth = FORM_ROW_MAX_WIDTH,
  breakpoint = "md",
  children,
  mainRowProps = {},
  sideRowProps = {},
  ...attributes
}: FormRowProps) {
  const theme = useTheme()

  const isMobile = useMediaQuery(theme.breakpoints.down(breakpoint))

  return (
    <Stack
      direction={isMobile ? "column" : "row"}
      spacing={2}
      alignItems="start"
      {...attributes}
    >
      <Stack
        width={"100%"}
        flexShrink={0}
        maxWidth={theme.spacing(getFormRowMaxSize(maxWidth))}
        {...mainRowProps}
      >
        {children ?? mainRowElement}
      </Stack>
      <Stack
        width={"100%"}
        maxWidth={
          isMobile ? theme.spacing(getFormRowMaxSize(maxWidth)) : undefined
        }
        flexGrow={1}
        {...mainRowProps}
      >
        {sideRowElement}
      </Stack>
    </Stack>
  )
}
